.sidebar {
  /* Auto layout */
  display: flex;
  right: -248px;
  top: 0;
  align-items: flex-start;
  padding: 24px 32px 24px 24px;
  position: relative;
  max-width: 248px;
  min-width: 248px;
  height: 100vh;
  
  overflow-x:hidden;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  transition: right 0.3s ease-in-out; /* Added transition */
}

@media only screen and (orientation:portrait){

  .sidebar {
    /* Auto layout */
    right: -248px;
    top: 0;
    bottom: 0;
    align-items: flex-start;
    padding: 24px 32px 24px 24px;
    max-width: 248px;
    min-height: 100%;
    position: fixed;
    overflow-x:hidden;
    overflow-y: auto;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    transition: right 0.3s ease-in-out; /* Added transition */
    
  }

}

.sidebar.show {
  right: 0; /* Sidebar position when open */
}

.items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 16px;
  width: 192px;
  
  /* Additional styles */
}
.sidebar-button {
  margin-top: 20px;

}

.found-text{
  font-size: 13px;
  font-weight: 400;
}


.category-block{
  /* Frame 71 */


/* Auto layout */

display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 16px;

width: 192px;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
margin-bottom: 20px;
}


.top-title {
  
/* Filters */


height: 15px;

font-family: 'Ubuntu';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 15px;
/* identical to box height */

display: flex;
align-items: flex-end;
text-transform: uppercase;

color: #666666;


/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;

}

.sidebar-title{
  /* SidebarTitle */


width: 192px;
height: 11px;


/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;

}

.sidebar-title p{
  position: relative;
left: 0%;
right: 87.5%;
top: 0%;
bottom: 0%;

font-family: 'Ubuntu';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 11px;
text-transform: uppercase;

color: #666666;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  
  width: 192px;
  height: 24px;
  
  
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  flex-grow: 0;
}

.item-value {
  font-size: 14px;
  color: #666666;
  
}


.item-count{
  padding: 4px 8px;
  background: #F2F6FB;
  border-radius: 11px;
  position: absolute;
  right: 13px;  
}
.item-number{
font-family: 'Ubuntu Mono';
font-size: 13px;
color: #25A6D6;
}

.sidebar-header  {

display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 20 0px;
gap: 16px;

width: 192px;
height: 50px;
padding-bottom: 50px;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
}

.box {
  transform: translateY(2px); /* Adjust the translateY value to move the checkbox down */
  margin-right: 10px;
}

.btn-close{
  margin-bottom: 34px;
  margin-right: 10px;
  font-size: 15px;
  
}


.btn-close:focus {
  box-shadow: none !important;
}

.badge{

width: 24px;
height: 24px;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
}

.icon{
  /* BadgePlugin 1 */


width: 24px;
height: 24px;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
}

.filter-button{
  height: 22px;
  width: 22px;
}

.filter-button:hover{
  cursor: pointer;
}


.item-logo {
height: 30px;
margin-right: 7px;
}

.filters-found {
  color: #25A6D6;
  display: flex;
  gap: 30px;
  flex-direction: row-reverse; 
  
}
