  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px 0px;
    height: 102px;
    background: #25A6D6;
  }

  .header-body{
    margin: 10px 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Add this line to create space between logo and dropdown */
    align-items: center; /* Add this line to vertically align logo and dropdown */  
  }
  @media (max-width: 1300px) {
    .header-body {
      margin: 10px 10px;
    }
  }
  

  .subheader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    background-color: #E2F4FD;
  }

  .subheader-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 16px;
    margin: 0px 10px
  }

    
  .subheader-body p {
  margin: 0;
  font-size: 0.75rem;
}

  .subheader-body a {
    color: #25A6D6
  }

  .subheader-body a:hover{
    color: #25a6d6;
  }

  .header .header-body .logo{
    height: 34px;
  }@media (min-resolution: 450dpi) {
    .logo {
      width: 250px;
    }
  }

.dropdown-custom .dropdown-toggle {
  color: white !important; /* Change the color of the toggle text to white */

}

.dropdown-custom .dropdown-toggle {
  border-color: #25A6D6 !important;
}

.dropdown-custom .dropdown-menu  {
  background-color: #25A6D6 !important; /* Change the color of the menu item text to white */
  padding: 0; /* Remove padding */
  border:none;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);

}
  

.dropdown-custom .dropdown-menu .dropdown-item  {
  background-color: #25A6D6 !important; /* Change the color of the menu item text to white */
  color: white;
  border-radius: 0;
  position: relative;
  padding-right: 2rem;

}

.dropdown-custom .dropdown-menu .dropdown-item:hover  {
  background-color: #9dcee7 !important; /* Change the color of the menu item text to white */
  border-radius: 0;
}

.dropdown-custom .dropdown-tick {
  position: absolute;
  top: 50%;
  right: 0.75rem; /* Adjust the position as per your preference */
  transform: translateY(-50%);
}