.footer {
    /* Fluid styles here */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 0px;
    width: auto;
    height: 78px;
    background: #0D1321;
    flex: none;
    order: 2;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .footer-body {
    /* Container styles here */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    width: 55%;
    height: 20px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .footer-text {
    width: 266px;
    height: 10px;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    white-space: nowrap;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  
  .footer .logo {
    width: 73.64px;
    height: 30px;
    flex: none;
    order: 1;
    flex-grow: 0;
}