
.search-icon {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  z-index: 1;
}

.form-control {
  text-indent: 30px; /* Move the placeholder text to the right */
  flex: 0.3 !important;
}
.form-control:focus{
  text-indent: 0px;
  border-color: #25A6D6 !important;
  box-shadow: none !important;
  
}

.form-control:focus::placeholder{
  color:transparent
}

.input-group{
  width: 90% !important;
  top: 33px;
}
