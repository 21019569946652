.columnContent {
  margin-top: 10px;
  text-align: center;
}
.pluginCard {
  display: table-row;
  min-height: 100px;
  background: #FFFFFF;
}
.cardTypePlugin{
  height: 200px;
}
.anchor {
  text-decoration: none
}
td {
  vertical-align: middle;
}
td:first-child {
  border-left-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.version {
  font-family: 'JetBrains Mono';
  font-size: 10px;
  line-height: 150%;
  font-weight: 700;  
  font-style: normal;
  color: #333333;
  border-color: #F2F6FB;
  background-color: #F2F6FB;
  padding: 7px;
  border-radius: 8px;
  display: inline;
  vertical-align: middle;
  margin-left: 10px; 
}
.type {
  width: 60px;
}
.compability {
  font-family: 'JetBrains Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  color: #B51237;
  border-color: #FDE8ED;
  background-color: #FDE8ED;
  padding: 7px;
  width: 124px;
  border-radius: 8px;
  margin-left: 15px;   
}
.more:hover {
  background-color: #25A6D6;
  cursor: pointer;
}
.btn.btn-primary-price {
  width: 75px;
  border-color: #FFFFFF;
  background-color: #FFFFFF; 
  color: #25A6D6;
  font-weight: 700;
  font-size: 13px;
}
.priceNumber {
  color: #25A6D6;
  font-weight: 700;
  font-size: 13px;  
}
.btn.btn-primary-price:hover {
  border-color: #25A6D6;
  background-color: #25A6D6;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 10px;
  white-space: nowrap;
  padding: 3px;
}
.text {
  min-height: 130px;
  margin-top: 10px;
}
.pluginName {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;
  color: #0D1321;
  flex-basis: 100%;
  word-break: break-word;
}
.pluginVendor {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #25A6D6;
  flex-basis: 100%;
  word-break: break-word;
}
.pluginDescription {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #666666;
  flex-basis: 100%;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 5px;
  text-align: justify;
}
.pluginCategory {
  font-family: 'Ubuntu';
  font-size: 10px;
  line-height: 150%;
  font-weight: 400;
  font-style: normal;
  color: #666666;
  border-color: #F2F6FB;
  background-color: #F2F6FB;
  padding: 7px;
  border-radius: 8px;
  display: inline;
  vertical-align: middle;
  margin-right: 10px;
}