
@media only screen and (orientation:portrait){

  .main {
    background-color: #F2F6FB;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    min-width: 100vh
   
  }

}


.main {
  background-color: #F2F6FB;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

#root {
  height: 100%;
}

.body {
  margin: 10px 300px;
  max-width: auto;
  flex: 1;
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  height: 100%;
}

html {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Media query for screens with width less than or equal to 600px */
@media (max-width: 1300px) {
  .body {
    margin: 10px 10px;
  }
}

.btn-primary.btn-roda {
  background: #25A6D6 ;
  border-color: #25A6D6 ;
  font-size: 0.6rem ;
}

.btn-primary.btn-roda:hover{
  background: #E2F4FD ;
  border-color: #25A6D6 ;
  font-size: 0.6rem ;
  color: #25A6D6;
}

.btn-primary.btn-roda:active{
  background: #25A6D6 !important;
  border-color: #25A6D6 ;
}
