.table {
  border-collapse: separate;
  border-spacing: 0 20px;
}
.nameColumn{
  text-align: left !important;
}
.table th {
  border: none;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
  text-align: center;
  text-transform: capitalize;
  color: #666666;
}

.pluginEntries{
  position: relative;
  left: 93%;
  top: 10px;
  
}

.noFilters{
  text-align: center;
  border-bottom-width: 0px !important
}
